<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="companyName" placeholder="客户名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="userName" placeholder="用户名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="courseName" placeholder="课程名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="serviceName" placeholder="客服"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-table
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.userList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          border
          v-loading="loading"
          height="650"
          max-height="650"
          style="width: 100%">
        <el-table-column prop="courseName" label="课程名" width="130" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="courseType" label="课程类型" width="125" align="center"></el-table-column>
        <el-table-column prop="companyName" label="客户名" width="140" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="用户" width="130" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="serviceName" label="客服" align="center"></el-table-column>
        <el-table-column prop="createDate" label="订单日期" width="130" align="center"></el-table-column>
        <el-table-column prop="startDate" label="开始日期" width="130" align="center"></el-table-column>
        <el-table-column prop="endDate" label="结束日期" width="130" align="center"></el-table-column>
        <el-table-column prop="coursePrice" label="课程价格" align="center"></el-table-column>
        <el-table-column prop="discountValue" label="折扣值" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.discountValue === "" ? "100%" : scope.row.discountValue + "%" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="reduction" label="立减" align="center"></el-table-column>
        <el-table-column prop="orderPrice" label="订单价格" align="center"></el-table-column>
        <el-table-column prop="proceedsMoney" label="收款金额" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
        <el-table-column width="250" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="openAddProceeds(scope.row)">添加收款</el-button>
              <el-button type="warning" size="mini" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteUser(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <orderEdit v-if="dialog.editOrderState" :order="dialog.order" :state="dialog.editOrderState" @close="close"/>
    <proceedsAdd v-if="dialog.addProceedsState" :order="dialog.order" :state="dialog.addProceedsState" @close="close"/>
  </div>
</template>

<script>
import orderEdit from "@/pages/train/order/order-edit";
import proceedsAdd from "@/pages/train/proceeds/proceeds-add";

export default {
  name: "order-list",
  data() {
    return {
      companyName: "",
      userName: "",
      courseName: "",
      serviceName: "",
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        userList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        editOrderState: false,
        addProceedsState: false,
        order: {},
      },
      loading: false,

    }
  },
  components: {
    orderEdit,proceedsAdd,
  },
  created() {
    this.queryOrderList();
  },
  methods: {
    search(){
      this.table.page=1;
      this.queryOrderList();
    },
    //查询客户列表
    queryOrderList: function () {
      this.loading = true
      this.$axios({
        method: "GET",
        url: "/trainOrder/queryTrainOrderList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          companyName: this.companyName,
          userName: this.userName,
          courseName: this.courseName,
          serviceName: this.serviceName,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.userList = response.data.data.list;
        this.loading = false
      })
    },
    //打开添加员工界面
    openEdit: function (data) {
      this.dialog.editOrderState = true
      this.dialog.order = data
    },
    openAddProceeds: function (data) {
      this.dialog.addProceedsState = true
      this.dialog.order = data
    },
    //删除员工
    deleteUser(data) {
      this.$confirm("删除订单", '删除订单:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/trainOrder/deleteTrainOrder",
          params: {
            id: data.id
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self = this;
            setTimeout(function () {
              self.queryOrderList()
            }, 1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryOrderList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryOrderList()
    },
    close: function (state) {
      this.dialog.editOrderState = state;
      this.dialog.addProceedsState = state;
      this.queryOrderList();
    }
  }
}
</script>

<style scoped>

</style>