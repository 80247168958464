<template>
  <el-dialog
      title="修改培训订单"
      width="55%"
      height="55%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="7">
          <el-form-item label="客户名称">
            <el-input readonly v-model="form.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="员工" prop="userId">
            <el-select v-model="form.userId" placeholder="员工" >
              <el-option
                  v-for="item in userArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="客服">
            <el-select v-model="form.serviceId" placeholder="客服">
              <el-option
                  v-for="item in serviceArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="课程名称" prop="courseId">
            <el-select v-model="form.courseId" placeholder="课程名称" @change="courseChange">
              <el-option
                  v-for="item in courseArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="课程价格">
            <el-input readonly type="number" v-model="form.coursePrice"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="折扣(%)">
            <el-select v-model="form.discountId" placeholder="折扣(%)" @change="discountChange">
              <el-option
                  v-for="item in discountArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="立减">
            <el-input type="number" v-model="form.reduction"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="订单总价" prop="orderPrice">
            <el-input type="number" v-model="form.orderPrice"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="21">
          <el-form-item label="备注">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4}"
                placeholder="请输入内容"
                v-model="form.remark">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editOrderCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "order-edit",
  data() {
    return {
      form: {
        //公司名
        id:this.order.id,
        companyName: this.order.companyName,
        userId: this.order.userId,
        serviceId: this.order.serviceId,
        courseId: this.order.courseId,
        coursePrice: this.order.coursePrice,
        discountId: this.order.discountId,
        //立减
        reduction: this.order.reduction,
        //订单总价
        orderPrice: this.order.orderPrice,
        remark: this.order.remark,
        tenantCrop:localStorage.getItem("tenantCrop"),
      },
      rules:{
        userId: [
          { required: true, message: '请选择员工', trigger: 'change' },
        ],
        courseId: [
          { required: true, message: '请选择课程', trigger: 'change' },
        ],
        orderPrice: [
          { required: true, message: '请选择课程', trigger: 'change' },
        ],

      },
      discountNum:1,
      userArray: [],
      serviceArray: [],
      courseArray: [],
      discountArray: [],
      dialogState: this.state,
    }
  },
  watch:{
    //监听订单价格
    'form.coursePrice':function (val,oldVal){
      this.form.orderPrice=Number(val)*this.discountNum-Number(this.form.reduction);
    },
    //监听立减
    'form.reduction':function (val,oldVal){
      this.form.orderPrice=Number(this.form.coursePrice)*this.discountNum-Number(val);
    },
    //监听折扣
    discountNum:function (val,oldVal){
      this.form.orderPrice=(Number(this.form.coursePrice)*Number(val)-Number(this.form.reduction));
    },

  },
  props: {
    order: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    console.log(this.order)
    this.queryUserArray();
    this.queryServiceArray();
    this.queryCourseArray();
    this.queryDiscountArray();
  },
  methods: {
    //检查订单校验
    editOrderCheck:function (){
      this.$refs['form'].validate((valid) => {
        if (valid){
          this.editOrder();
        }
      })
    },
    //添加订单
    editOrder: function () {
      let value="是否修改订单?"
      this.$confirm(value, '修改订单:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/trainOrder/updateTrainOrder",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("添加成功!")
            let self=this;
            setTimeout(function (){self.close()},1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //课程切换查询
    courseChange:function (value){
      this.queryCoursePrice(value);
    },
    //折扣切换
    discountChange:function (value){
      this.discountNum= (this.discountArray.filter(k=>k.value===value)[0].name/100).toFixed(2);
    },
    //查询用户
    queryUserArray: function () {
      this.$selectUtils.queryTrainUserIds(this.order.companyId).then(response => {
        this.userArray = JSON.parse(response.data.data)
      })
    },
    //查询客服
    queryServiceArray: function () {
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data)
      })
    },
    //查询课程
    queryCourseArray: function () {
      this.$selectUtils.queryTrainCourseIds().then(response => {
        this.courseArray = JSON.parse(response.data.data)
      })
    },
    //查询折扣
    queryDiscountArray: function () {
      this.$selectUtils.queryDiscountIds().then(response => {
        this.discountArray = JSON.parse(response.data.data)
      })
    },
    //根据Id查询课程
    queryCoursePrice:function (courseId){
      this.$axios({
        method: 'get',
        url: '/trainCourse/queryTrainCourseById',
        params: {
          id: courseId
        }
      }).then(response => {
        this.form.coursePrice=response.data.data.price
        console.log(response);
      });
    },
    close:function (){
      this.$emit("close",false);
    }
  }
}
</script>

<style scoped>

</style>